import './Loader.css';

export default function Loader(props) {
    return (
		<div id="preloader">
			<div className="waviy">
			</div>
		</div>
    );
}
