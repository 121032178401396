import React, { Component } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import { ThemeContext } from '../../../context/ThemeContext'
import spinner from '../../../images/spinner.gif';
import Web3 from 'web3';
import {rpc,contracts,tokens} from '../../../env.json';
import {abi} from '../../../token_abi.json';
import {abi as contract_abi} from '../../../dist_abi.json';

export default class BSC extends Component {
    static contextType = ThemeContext;
    state = {
        provider:null,
        ether:0,
        dist_percent:0,
        rate:0,
        min_amount:0,
        eth_bal:0,
        bal:0,
        receivable:0,
        loading:false
    }
    async componentDidMount(){
        this.setState({provider:window.ethereum,loading:true});
        try{
            let web3 = new Web3(rpc.avax);
            let eth_bal = 0;
            // let token = new web3.eth.Contract(abi,tokens.avax);
            let bal = 0;
            let contract = new web3.eth.Contract(contract_abi,contracts.avax);
            let m_amount = await contract.methods.getMinAmount().call();
            let min_amount = Number(web3.utils.fromWei(m_amount));
            let rate = await contract.methods.getRate().call();
            // let {charity,development,liquidity,marketing,reserve,team} = await contract.methods.getTokenomics().call();
            // 45691749675468835391
            let dist_percent = 0;
            // console.log(dist);
            let receivable = min_amount*Number(rate);
            this.setState({ether:min_amount,rate:Number(rate),min_amount,dist_percent,receivable,loading:false});
            await this.connectWallet();
            if(this.context.account != null){
                eth_bal = await web3.eth.getBalance(this.state.provider.selectedAddress);
                let token = new web3.eth.Contract(abi,tokens.avax);
                bal = await token.methods.balanceOf(this.context.account).call();
            }
            this.setState({bal,eth_bal})
            console.log("State:",this.state);
        }catch(e){
            this.setState({loading:false});
            console.log(e);
        }
    }
    connectWallet = async ()=>{
        await window.ethereum.enable();
        let web3 =new Web3(this.state.provider);
        let accounts = await web3.eth.getAccounts();
        this.context.logIn(accounts[0]);
    }
    handleFormUpdate = (e)=>{
        let ether = Number(e.target.value);
        if(ether < this.state.min_amount){
            let receivable = this.state.min_amount * this.state.rate * (1-(this.state.dist_percent/100));
            this.setState({ether:this.state.min_amount,receivable})
        }else{
            let receivable = ether * this.state.rate * (1-(this.state.dist_percent/100));
            this.setState({ether,receivable})
        }
    }
    switchToBSC = async ()=>{
        try {
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: '0xA86A' }],
            });
        } catch (switchError) {
            if (switchError.code === 4902) {
                try {
                  await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [{ chainId: '0xA86A', rpcUrl: 'https://api.avax.network/ext/bc/C/rpc',
                    nativeCurrency: {
                        name: "Avalanche",
                        symbol: "AVAX", // 2-6 characters long
                        decimals: 18
                    },
                    blockExplorerUrls:[
                        'https://avascan.com'
                    ]
                    }],
                  });
                } catch (addError) {
                    alert("Something went wrong!");
                }
            }
        }
    }
    purchaseToken = async (e)=>{
        e.preventDefault();
        // e.stopPropagation();
        this.setState({loading:true});
        // console.log("case here")
        try {
            await this.connectWallet();
        // console.log("case here")
        await this.switchToBSC();
        // console.log("case here")
        let web3 = new Web3(window.ethereum);
            let contract = new web3.eth.Contract(contract_abi,contracts.avax);
            // if(this.state.ether < this.state.eth_bal){
                console.log("case here")
                await contract.methods.purchase().send({from:this.context.account,gas:74891,value:web3.utils.toWei(this.state.ether.toString(),'ether')})
                .on('transactionHash',(hash)=>{
                    this.info();
                    // console.log(hash);
                })
                .once('confirmation',((number,receipt)=>{
                    this.success(receipt.transactionHash.substr(0,9)+'...'+receipt.transactionHash.substr(receipt.transactionHash.length-7,7));
                    this.setState({loading:false});
                }).bind(this));
                // console.log("case here")
            // }else{

            // }
        } catch (error) {
            this.setState({loading:false});
            console.log(error)
        }
    }
    success = (txHash ="0x1df30bc...2dfac4b") => {
        toast.success(`Success ${txHash}`, {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
    }
    info = () => {
        toast.info(`Waiting for confirmation`, {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
    }
    render() {
        return (
            <div className="container d-flex justify-content-center position-relative">
                <ToastContainer
                  position='bottom-right'
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />
                <div className="col-12 col-md-7 col-lg-5">
                    <div className="card">
                        <div className="card-header border-0 pb-0">
                            <h4 className="card-title">Buy WORK Token (AVAX) </h4>
                        </div>
                        <div className="card-body">
                            <form on onSubmit={this.purchaseToken}>
                                <div className="form-group mb-3">
                                    <label>AVAX Amount ({this.state.min_amount} AVAX)</label>
                                    <input type="number" style={{fontSize:'18px'}} step="0.01" value={this.state.ether} onChange={this.handleFormUpdate} className="form-control input-default" />
                                </div>
                                <div className="form-group mb-5">
                                    <label>You will Receive</label>
                                    <input className="form-control input-default disabled" value={this.state.receivable} disabled/>
                                </div>
                                <div className="form-group">
                                    <label>Distribution : {this.state.rate*this.state.ether*this.state.dist_percent/100} WORK</label>
                                    {/* <label>Hello world Fees: $0.02</label> */}
                                </div>
                                <div className="form-group mb-3">
                                    <button type="submit" className="btn btn-primary w-100"> Purchase WORK</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {this.state.loading && <div className="d-flex justify-content-center align-items-center" style={{position:'fixed',zIndex:'1',backgroundColor:'#000000a9',top:0,left:0,right:0,bottom:0}}>
                    <img src={spinner} alt="" height="100" />
                </div> }
            </div>
        )
    }
}
