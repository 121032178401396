import React from "react";
import { Dropdown } from "react-bootstrap";
import { ThemeContext } from "../../../context/ThemeContext";
import Web3 from 'web3';
// import {rpc} from 
const Header = ({ onNote, toggle, onProfile, onNotification, onClick }) => {
  var path = window.location.pathname.split("/");
  var name = path[path.length - 1].split("-");
  var filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;
  var finalName = filterName.includes("app")
    ? filterName.filter((f) => f !== "app")
    : filterName.includes("ui")
    ? filterName.filter((f) => f !== "ui")
    : filterName.includes("uc")
    ? filterName.filter((f) => f !== "uc")
    : filterName.includes("basic")
    ? filterName.filter((f) => f !== "basic")
    : filterName.includes("jquery")
    ? filterName.filter((f) => f !== "jquery")
    : filterName.includes("table")
    ? filterName.filter((f) => f !== "table")
    : filterName.includes("page")
    ? filterName.filter((f) => f !== "page")
    : filterName.includes("email")
    ? filterName.filter((f) => f !== "email")
    : filterName.includes("ecom")
    ? filterName.filter((f) => f !== "ecom")
    : filterName.includes("chart")
    ? filterName.filter((f) => f !== "chart")
    : filterName.includes("editor")
    ? filterName.filter((f) => f !== "editor")
    : filterName;
	function getBalance(acc){
		let web3 = new Web3(window.ethereum);
		web3.eth.getBalance(acc).then(bal=>{
			return web3.utils.fromWei(bal);
		})
	}
	async function connectWallet(vals){
		try{
			await window.ethereum.enable();
			vals.logIn(window.ethereum.selectedAccount);
		}catch(e){
			console.log(e);
		}
	}
  return (
    <div className="header">
		<ThemeContext.Consumer>
			{vals=>(
		<div className="header-content">
			<nav className="navbar navbar-expand">
				<div className="collapse navbar-collapse justify-content-between">
					<div className="header-left">
						<div
							className="dashboard_bar"
							style={{ textTransform: "capitalize" }}
						  >
							{finalName.join(" ").length === 0
							  ? "BSC ICO"
							  : `${finalName.join(" ")} ICO`}
						</div>
					</div> 	
					
					<ul className="navbar-nav header-right">
						<Dropdown as="li" className="nav-item header-profile ">
							<Dropdown.Toggle as="a" to="#" variant="" className="nav-link i-false c-pointer">
								{vals.account!=null && <><div className="header-info">
									<span>{vals.account.substr(0,7)+'....'+vals.account.substr(vals.account.length-5,5)}</span>
								</div>
								<span style={{padding:'15px 20px',backgroundColor:'#ffffff',color:"#000000",borderRadius:'0.75rem'}}>{vals.account.substr(2,2)}</span></>}
                                {vals.account == null && <span style={{padding:'15px 20px',backgroundColor:'#ffffff',color:"#000000",borderRadius:'0.75rem'}} onClick={()=>{connectWallet(vals)}}>Connect Wallet</span>}
							</Dropdown.Toggle>
						</Dropdown>
					</ul>
				
				</div>
			</nav>
		</div>
		)}
		</ThemeContext.Consumer>

    </div>
  );
};

export default Header;
