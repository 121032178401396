/// Menu
import MetisMenu from "metismenujs";
import React, { Component } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() {
  }
    render() {
        return (
            <div className="mm-wrapper">
                <ul className="metismenu" ref={(el) => (this.el = el)}>
                    {this.props.children}
                </ul>
            </div>
        );
    }
}

class SideBar extends Component {
  /// Open menu
  componentDidMount() {
    // sidebar open/close
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
  }
  state = {
    loveEmoji: false,
  };
  render() {
    /// Path
    let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];
    
    /// Active menu
      let dashBoard = [
        "",
      ],
      avalanche = [
        "avalanche",
      ],
      ethereum = [
        "ethereum",
      ],
      app = [
        "app-profile",
        "app-calender",
        "email-compose",
        "email-inbox",
        "email-read",
        "ecom-product-grid",
        "ecom-product-list",
        "ecom-product-list",
        "ecom-product-order",
        "ecom-checkout",
        "ecom-invoice",
        "ecom-customers",
        "post-details",
        "ecom-product-detail",
      ],
      email = ["email-compose", "email-inbox", "email-read"],
      shop = [
        "ecom-product-grid",
        "ecom-product-list",
        "ecom-product-list",
        "ecom-product-order",
        "ecom-checkout",
        "ecom-invoice",
        "ecom-customers",
        "ecom-product-detail",
      ],
      charts = [
        "chart-rechart",
        "chart-flot",
        "chart-chartjs",
        "chart-chartist",
        "chart-sparkline",
        "chart-apexchart",
      ],
      bootstrap = [
        "ui-accordion",
        "ui-badge",
        "ui-alert",
        "ui-button",
        "ui-modal",
        "ui-button-group",
        "ui-list-group",
        "ui-media-object",
        "ui-card",
        "ui-carousel",
        "ui-dropdown",
        "ui-popover",
        "ui-progressbar",
        "ui-tab",
        "ui-typography",
        "ui-pagination",
        "ui-grid",
      ],
      plugins = [
        "uc-select2",
        "uc-nestable",
        "uc-sweetalert",
        "uc-toastr",
        "uc-noui-slider",
        "map-jqvmap",
        //"post",
        
      ],
        redux = [
            "todo",
            "form-redux",
            "form-redux-wizard", 
        ],
      widget = ["widget-basic"],
      forms = [
        "form-element",
        "form-wizard",
        "form-editor-summernote",
        "form-pickers",
        "form-validation-jquery",
      ],
      table = [
        "table-bootstrap-basic", 
        "table-datatable-basic",
        "table-sorting",
        "table-filtering",
      ],
      pages = [
        "page-register",
        "page-login",
        "page-lock-screen",
        "page-error-400",
        "page-error-403",
        "page-error-404",
        "page-error-500",
        "page-error-503",
      ],
      error = [
        "page-error-400",
        "page-error-403",
        "page-error-404",
        "page-error-500",
        "page-error-503",
      ];

    return (
      <div className="deznav">
        <PerfectScrollbar className="deznav-scroll">
          <MM className="metismenu" id="menu">
            <li className={`${dashBoard.includes(path) ? "mm-active" : ""}`}>
              <Link className="ai-icon" to="/" >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2500.01 2500"><path fill="#ffffff" d="M764.48,1050.52,1250,565l485.75,485.73,282.5-282.5L1250,0,482,768l282.49,282.5M0,1250,282.51,967.45,565,1249.94,282.49,1532.45Zm764.48,199.51L1250,1935l485.74-485.72,282.65,282.35-.14.15L1250,2500,482,1732l-.4-.4,282.91-282.12M1935,1250.12l282.51-282.51L2500,1250.1,2217.5,1532.61Z"/><path fill="#ffffff" d="M1536.52,1249.85h.12L1250,963.19,1038.13,1175h0l-24.34,24.35-50.2,50.21-.4.39.4.41L1250,1536.81l286.66-286.66.14-.16-.26-.14"/></svg>
                <span className="nav-text">BSC</span>
              </Link>
            </li>
            <li className={`${avalanche.includes(path) ? "mm-active" : ""}`}>
              <Link className="ai-icon" to="/avalanche" style={{color:'#ffffff'}} >
              <svg xmlns="http://www.w3.org/2000/svg" width="647" height="647" viewBox="0 0 647 647" fill="none"><path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" d="M323.652 647C502.316 647 647.152 502.164 647.152 323.5C647.152 144.836 502.316 0 323.652 0C144.988 0 0.152344 144.836 0.152344 323.5C0.152344 502.164 144.988 647 323.652 647ZM225.905 470.06H150.015C132.022 470.06 123.025 470.06 117.637 466.575C111.814 462.81 108.14 456.491 107.751 449.572C107.39 443.169 111.846 435.362 120.758 419.75L120.762 419.744L286.919 128.655C295.962 112.811 300.484 104.889 306.238 101.94C312.453 98.7542 319.824 98.7487 326.044 101.926C331.802 104.866 336.335 112.782 345.401 128.612L383.723 195.52L383.724 195.522C390.317 207.032 393.613 212.788 395.063 218.86C396.641 225.471 396.644 232.358 395.072 238.969C393.628 245.043 390.336 250.803 383.752 262.32L284.392 436.139C277.713 447.825 274.373 453.668 269.797 458.035C264.816 462.789 258.776 466.293 252.174 468.257C246.108 470.06 239.374 470.06 225.905 470.06ZM496.658 470.06H408.672C390.516 470.06 381.437 470.06 376.032 466.545C370.192 462.747 366.529 456.381 366.184 449.429C365.864 442.995 370.433 435.155 379.571 419.479L423.491 344.127C432.514 328.649 437.026 320.91 442.737 318.011C448.91 314.878 456.209 314.873 462.387 317.996C468.104 320.886 472.625 328.619 481.668 344.082L481.67 344.087L525.736 419.437C534.909 435.123 539.495 442.967 539.182 449.406C538.843 456.364 535.182 462.737 529.339 466.541C523.934 470.06 514.842 470.06 496.658 470.06Z" fill="#ffffff"/> </svg>
                <span className="nav-text">AVALANCHE</span>
              </Link>
            </li>
            {/* <li className={`${ethereum.includes(path) ? "mm-active" : ""}`}>
              <Link className="ai-icon" to="/ethereum" >
              <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 784.37 1277.39">
                  <polygon fill="#ffffff" fill-rule="nonzero" points="392.07,0 383.5,29.11 383.5,873.74 392.07,882.29 784.13,650.54 "/>
                  <polygon fill="#ffffff" fill-rule="nonzero" points="392.07,0 -0,650.54 392.07,882.29 392.07,472.33 "/>
                  <polygon fill="#ffffff" fill-rule="nonzero" points="392.07,956.52 387.24,962.41 387.24,1263.28 392.07,1277.38 784.37,724.89 "/>
                  <polygon fill="#ffffff" fill-rule="nonzero" points="392.07,1277.38 392.07,956.52 -0,724.89 "/>
                  <polygon fill="#ffffff" fill-rule="nonzero" points="392.07,882.29 784.13,650.54 392.07,472.33 "/>
                  <polygon fill="#ffffff" fill-rule="nonzero" points="0,650.54 392.07,882.29 392.07,472.33 "/>
              </svg>
                <span className="nav-text">ETHEREUM</span>
              </Link>
            </li> */}
          </MM>
		  <div className="plus-box">
			<p className="fs-16 font-w500 mb-1">Visit our website now</p>
			<a href="http://coinworks.co" target="_blank" className="text-white fs-26"><i className="las la-long-arrow-alt-right"></i></a>
		</div>
			<div className="copyright">
				<p className="fs-14 font-w200"><strong className="font-w400">Works</strong> © 2021 All Rights Reserved</p>
				<p>Made with <i className="fa fa-heart text-danger"></i> by Code Partner</p>
			</div>
			</PerfectScrollbar>
      </div>
    );
  }
}

export default SideBar;
