import React, { useState } from "react";

/// React router dom
import { Link } from "react-router-dom";

/// images
import logo from "../../../images/logo.png";
import logoText from "../../../images/logo-text.png";

const NavHader = () => {
   const [toggle, setToggle] = useState(false);
   return (
      <div className="nav-header">
         <Link to="/dashboard" className="brand-logo">
         <div className="d-flex align-items-center justify-content-c">
            <svg xmlns="http://www.w3.org/2000/svg" className="logo-compact" viewBox="0 0 1000 1000" height="60" style={{display:"block"}}>
            <g>
               <g>
                  <polygon fill="#FFFFFF" points="379.6,735.9 39.5,248.3 66.7,228.7 379.6,677.3 509.2,492.1 647.2,662.1 932.2,201.2 960.5,219.3     650.9,719.9 511.1,547.8   "/>
               </g>
               <polygon fill="#FFFFFF" points="121.2,198.9 178.2,156.5 386.5,449.2 533.8,239.9 469.9,162.1 424,217.3 460.2,258.3 417.1,314.9    333.7,223 471.3,43.4 617.1,238.5 389.3,570.8  "/>
               <polygon fill="#FFFFFF" points="826.9,128.2 882.5,172 643.5,570.8 535.2,435.1 576.8,375.7 635.2,446.4  "/>
            </g>
            {/* <g>
               <path fill="#FFFFFF" d="M237.5,912.5l-26.1-108.4H234l16.5,74.4l20-74.4h26.2l19.2,75.7l16.8-75.7h22.2l-26.5,108.4h-23.4l-21.8-81   l-21.7,81H237.5z"/>
               <path fill="#FFFFFF" d="M361.6,859c0-11,1.7-20.3,5-27.8c2.5-5.5,5.9-10.5,10.2-14.9c4.3-4.4,9-7.6,14.1-9.8   c6.8-2.9,14.7-4.3,23.6-4.3c16.1,0,29,5,38.6,14.9c9.7,9.9,14.5,23.7,14.5,41.3c0,17.5-4.8,31.2-14.4,41.1   c-9.6,9.9-22.4,14.8-38.5,14.8c-16.2,0-29.2-4.9-38.8-14.7C366.4,889.7,361.6,876.2,361.6,859z M384.4,858.2   c0,12.3,2.9,21.6,8.6,27.9c5.7,6.3,13,9.5,21.8,9.5s16-3.1,21.7-9.4c5.6-6.3,8.5-15.7,8.5-28.3c0-12.4-2.7-21.7-8.2-27.8   c-5.5-6.1-12.8-9.2-21.9-9.2c-9.1,0-16.4,3.1-22,9.3C387.1,836.4,384.4,845.7,384.4,858.2z"/>
               <path fill="#FFFFFF" d="M484.9,912.5V804.1h46.4c11.7,0,20.2,1,25.5,2.9c5.3,1.9,9.5,5.4,12.7,10.4c3.2,5,4.8,10.7,4.8,17.1   c0,8.1-2.4,14.8-7.2,20.1c-4.8,5.3-12,8.6-21.6,10c4.8,2.8,8.7,5.8,11.8,9.1c3.1,3.3,7.3,9.2,12.6,17.6l13.3,21.1h-26.4l-15.9-23.6   c-5.7-8.4-9.5-13.7-11.6-15.9c-2.1-2.2-4.3-3.7-6.6-4.5c-2.3-0.8-6-1.2-11.1-1.2H507v45.2H484.9z M507,849.9h16.3   c10.6,0,17.2-0.4,19.8-1.3c2.6-0.9,4.7-2.4,6.2-4.6c1.5-2.2,2.2-4.9,2.2-8.1c0-3.6-1-6.6-2.9-8.8c-2-2.2-4.7-3.7-8.3-4.3   c-1.8-0.2-7.2-0.4-16.1-0.4H507V849.9z"/>
               <path fill="#FFFFFF" d="M595.4,912.5V804.1h22.1v48.1l44.6-48.1h29.7l-41.1,42.2l43.4,66.2h-28.5l-30-50.9l-17.9,18.1v32.8H595.4z"/>
               <path fill="#FFFFFF" d="M699.7,877.2l21.5-2.1c1.3,7.1,3.9,12.4,7.9,15.7c4,3.4,9.3,5,16,5c7.1,0,12.5-1.5,16.1-4.5   c3.6-3,5.4-6.5,5.4-10.5c0-2.6-0.8-4.7-2.3-6.5c-1.5-1.8-4.2-3.4-7.9-4.7c-2.6-0.9-8.5-2.5-17.7-4.7c-11.8-2.9-20.1-6.5-24.9-10.7   c-6.7-6-10.1-13.2-10.1-21.8c0-5.5,1.6-10.7,4.7-15.5c3.2-4.8,7.7-8.5,13.6-11c5.9-2.5,13.1-3.8,21.5-3.8c13.7,0,24,3,31,8.9   c6.9,6,10.6,13.9,10.9,23.9l-22.1,1c-0.9-5.6-3-9.6-6.1-12c-3.1-2.4-7.8-3.7-14-3.7c-6.4,0-11.4,1.3-15.1,3.9   c-2.3,1.7-3.5,3.9-3.5,6.7c0,2.6,1.1,4.8,3.3,6.6c2.8,2.3,9.5,4.7,20.3,7.2c10.7,2.5,18.7,5.1,23.8,7.8c5.1,2.7,9.2,6.4,12.1,11   c2.9,4.7,4.4,10.4,4.4,17.3c0,6.2-1.7,12-5.2,17.4c-3.5,5.4-8.4,9.5-14.8,12.1c-6.4,2.6-14.3,4-23.8,4c-13.8,0-24.4-3.2-31.8-9.5   C705.6,898.6,701.1,889.3,699.7,877.2z"/>
            </g> */}
            </svg>
            {/* <img className="logo-compact" src={logoText} alt="" /> */}
            {/* <img className="brand-title" src={logoText} alt="" /> */}
            <h2 className="ps-2 brand-title">WORKS</h2>
         </div>
         </Link>

         <div className="nav-control" onClick={() => setToggle(!toggle)}>
            <div className={`hamburger ${toggle ? "is-active" : ""}`}>
               <span className="line"></span>
               <span className="line"></span>
               <span className="line"></span>
            </div>
         </div>
      </div>
   );
};

export default NavHader;
